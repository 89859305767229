/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 27 Mar 2025 14:39:14 GMT
 */

export default {
  scale: {
    gray: {
      "100": "#C7CED6",
      "200": "#B5BEC9",
      "300": "#A3AEBD",
      "400": "#929FB0",
      "500": "#6B778C",
      "600": "#505F79",
      "700": "#344563",
      "800": "#172B4D",
      "900": "#091E42",
      "000": "#FAFBFC",
    },
    neutral: {
      "100": "#1D2125",
      "200": "#22272B",
      "250": "#282E33",
      "300": "#2C333A",
      "350": "#38414A",
      "400": "#454F59",
      "500": "#596773",
      "600": "#738496",
      "700": "#8C9BAB",
      "800": "#9FADBC",
      "900": "#B6C2CF",
      "1000": "#C7D1DB",
      "1100": "#DEE4EA",
      "000": "#161A1D",
    },
    neutralAlpha: {
      "100": "#bcd6f00a",
      "200": "#a1bdd914",
      "250": "#C8E1F91A",
      "300": "#a6c5e229",
      "350": "#C3DEFE33",
      "400": "#bfdbf847",
      "500": "#9bb4ca80",
      "600": "#9bb4caa3",
      "700": "#9bb4cab0",
      "800": "#a4bfd7e3",
      "900": "#b4d0e9f7",
      "000": "#00000000",
    },
    blue: {
      "100": "#CCE0FF",
      "200": "#85B8FF",
      "300": "#579DFF",
      "400": "#388BFF",
      "500": "#1D7AFC",
      "600": "#0C66E4",
      "700": "#0055CC",
      "800": "#09326C",
      "900": "#1C2B41",
      "000": "#E9F2FF",
    },
    teal: {
      "100": "#C6EDFB",
      "200": "#9DD9EE",
      "300": "#6CC3E0",
      "400": "#42B2D7",
      "500": "#2898BD",
      "600": "#227D9B",
      "700": "#206A83",
      "800": "#164555",
      "900": "#1E3137",
      "000": "#E7F9FF",
    },
    purple: {
      "100": "#DFD8FD",
      "200": "#B8ACF6",
      "300": "#9F8FEF",
      "400": "#8F7EE7",
      "500": "#8270DB",
      "600": "#6E5DC6",
      "700": "#5E4DB2",
      "800": "#352C63",
      "900": "#2B273F",
      "000": "#F3F0FF",
    },
    green: {
      "100": "#BAF3DB",
      "200": "#7EE2B8",
      "300": "#4BCE97",
      "400": "#2ABB7F",
      "500": "#22A06B",
      "600": "#1F845A",
      "700": "#216E4E",
      "800": "#164B35",
      "900": "#1C3329",
      "000": "#DCFFF1",
    },
    yellow: {
      "100": "#F8E6A0",
      "200": "#F5CD47",
      "300": "#E2B203",
      "400": "#CF9F02",
      "500": "#B38600",
      "600": "#946F00",
      "700": "#7F5F01",
      "800": "#533F04",
      "900": "#332E1B",
      "000": "#FFF7D6",
    },
    red: {
      "100": "#ffd5d2",
      "200": "#fd9891",
      "300": "#f87168",
      "400": "#f15b50",
      "500": "#e2483d",
      "600": "#c9372c",
      "700": "#ae2e24",
      "800": "#5d1f1a",
      "900": "#42221f",
      "000": "#ffeceb",
    },
    orange: {
      "100": "#fedec8",
      "200": "#fec195",
      "300": "#fea362",
      "400": "#f38a3f",
      "500": "#e56910",
      "600": "#c25100",
      "700": "#a54800",
      "800": "#702e00",
      "900": "#38291e",
      "000": "#fff3eb",
    },
    magenta: {
      "100": "#FDD0EC",
      "200": "#F797D2",
      "300": "#E774BB",
      "400": "#DA62AC",
      "500": "#CD519D",
      "600": "#AE4787",
      "700": "#943D73",
      "800": "#50253F",
      "900": "#3D2232",
      "000": "#FFECF8",
    },
    lime: {
      "100": "#D3F1A7",
      "200": "#B3DF72",
      "300": "#94C748",
      "400": "#82B536",
      "500": "#6A9A23",
      "600": "#5B7F24",
      "700": "#4C6B1F",
      "800": "#37471F",
      "900": "#28311B",
      "000": "#EFFFD6",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#1D2125",
        hovered: "#1D2125",
        pressed: "#22272B",
      },
      sunken: "#161A1D",
      raised: {
        default: "#22272B",
        hovered: "#282E33",
        pressed: "#2C333A",
      },
      overlay: {
        default: "#22272B",
        hovered: "#2C333A",
        pressed: "#2C333A",
      },
    },
    shadow: {
      raised: "0px 1px 1px 0px #03040480, 0px 0px 1px 0px #03040480",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#0304048f",
        perimeter: "#091e421f",
      },
      overlay: "0px 0px 0px 1px #bcd6f00a, 0px 8px 12px 0px #0304045c, 0px 0px 1px 0px #03040480",
    },
  },
  border: {
    default: "#a6c5e229",
    bold: "#738496",
    selected: "#579DFF",
    focused: "#85B8FF",
    subtle: "#bfdbf847",
    input: "#738496",
    inverse: "#161A1D",
    disabled: "#a1bdd914",
    brand: "#579DFF",
    danger: "#f15b50",
    warning: "#f38a3f",
    success: "#2ABB7F",
    discovery: "#8F7EE7",
    information: "#388BFF",
    accent: {
      blue: "#388BFF",
      red: "#f15b50",
      orange: "#fea362",
      yellow: "#CF9F02",
      green: "#2ABB7F",
      purple: "#8F7EE7",
      teal: "#42B2D7",
      magenta: "#DA62AC",
      gray: "#738496",
    },
    table: {
      default: "#a6c5e229",
      container: "#00000000",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#1C2B41",
        subtler: "#09326C",
        subtle: "#0055CC",
        bolder: "#579DFF",
      },
      red: {
        subtlest: "#42221f",
        subtler: "#5d1f1a",
        subtle: "#ae2e24",
        bolder: "#f87168",
      },
      orange: {
        subtlest: "#38291e",
        subtler: "#702e00",
        subtle: "#a54800",
        bolder: "#fea362",
      },
      yellow: {
        subtlest: "#332E1B",
        subtler: "#533F04",
        subtle: "#7F5F01",
        bolder: "#E2B203",
      },
      green: {
        subtlest: "#1C3329",
        subtler: "#164B35",
        subtle: "#216E4E",
        bolder: "#4BCE97",
      },
      purple: {
        subtlest: "#2B273F",
        subtler: "#352C63",
        subtle: "#5E4DB2",
        bolder: "#9F8FEF",
      },
      teal: {
        subtlest: "#1E3137",
        subtler: "#164555",
        subtle: "#206A83",
        bolder: "#6CC3E0",
      },
      magenta: {
        subtlest: "#3D2232",
        subtler: "#50253F",
        subtle: "#943D73",
        bolder: "#E774BB",
      },
      gray: {
        subtlest: {
          default: "#2C333A",
          hovered: "#38414A",
          pressed: "#454F59",
        },
        subtler: {
          default: "#454F59",
          hovered: "#596773",
          pressed: "#738496",
        },
        subtle: {
          default: "#596773",
          hovered: "#454F59",
          pressed: "#38414A",
        },
        bolder: {
          default: "#8C9BAB",
          hovered: "#9FADBC",
          pressed: "#B6C2CF",
        },
      },
      lime: {
        subtlest: "#28311B",
        subtler: "#37471F",
        subtle: "#4C6B1F",
        bolder: "#94C748",
      },
    },
    input: {
      default: "#1D2125",
      hovered: "#22272B",
      pressed: "#1D2125",
    },
    inverse: {
      subtle: {
        default: "#FFFFFF29",
        hovered: "#FFFFFF3D",
        pressed: "#FFFFFF52",
      },
    },
    neutral: {
      default: {
        "[default]": "#a1bdd914",
        hovered: "#a6c5e229",
        pressed: "#bfdbf847",
      },
      subtle: {
        default: "#00000000",
        hovered: "#a1bdd914",
        pressed: "#a6c5e229",
      },
      bold: {
        default: "#9FADBC",
        hovered: "#B6C2CF",
        pressed: "#C7D1DB",
      },
    },
    brand: {
      subtlest: {
        default: "#1C2B41",
        hovered: "#09326C",
        pressed: "#0055CC",
      },
      bold: {
        default: "#579DFF",
        hovered: "#85B8FF",
        pressed: "#CCE0FF",
      },
      boldest: {
        default: "#E9F2FF",
        hovered: "#CCE0FF",
        pressed: "#85B8FF",
      },
    },
    selected: {
      default: {
        "[default]": "#1C2B41",
        hovered: "#09326C",
        pressed: "#09326C",
      },
      bold: {
        default: "#579DFF",
        hovered: "#85B8FF",
        pressed: "#CCE0FF",
      },
    },
    disabled: "#a1bdd914",
    information: {
      default: {
        "[default]": "#1C2B41",
        hovered: "#09326C",
        pressed: "#0055CC",
      },
      bold: {
        default: "#579DFF",
        hovered: "#85B8FF",
        pressed: "#CCE0FF",
      },
    },
    danger: {
      default: {
        "[default]": "#42221f",
        hovered: "#5d1f1a",
        pressed: "#ae2e24",
      },
      bold: {
        default: "#f87168",
        hovered: "#fd9891",
        pressed: "#ffd5d2",
      },
    },
    success: {
      default: {
        "[default]": "#1C3329",
        hovered: "#164B35",
        pressed: "#216E4E",
      },
      bold: {
        default: "#4BCE97",
        hovered: "#7EE2B8",
        pressed: "#BAF3DB",
      },
    },
    discovery: {
      default: {
        "[default]": "#2B273F",
        hovered: "#352C63",
        pressed: "#5E4DB2",
      },
      bold: {
        default: "#9F8FEF",
        hovered: "#B8ACF6",
        pressed: "#DFD8FD",
      },
    },
    warning: {
      default: {
        "[default]": "#332E1B",
        hovered: "#533F04",
        pressed: "#7F5F01",
      },
      bold: {
        default: "#F5CD47",
        hovered: "#E2B203",
        pressed: "#CF9F02",
      },
    },
  },
  blanket: {
    default: "#10121499",
    selected: "#1d7afc14",
    danger: "#e3493514",
  },
  text: {
    default: "#B6C2CF",
    subtle: "#9FADBC",
    subtlest: "#8C9BAB",
    disabled: "#bfdbf847",
    selected: "#579DFF",
    inverse: "#161A1D",
    success: "#7EE2B8",
    danger: "#fd9891",
    information: "#85B8FF",
    warning: {
      default: "#F5CD47",
      inverse: "#161A1D",
    },
    accent: {
      blue: {
        default: "#85B8FF",
        bolder: "#CCE0FF",
      },
      red: {
        default: "#fd9891",
        bolder: "#ffd5d2",
      },
      orange: {
        default: "#fec195",
        bolder: "#fedec8",
      },
      yellow: {
        default: "#F8E6A0",
        bolder: "#F8E6A0",
      },
      green: {
        default: "#7EE2B8",
        bolder: "#BAF3DB",
      },
      purple: {
        default: "#B8ACF6",
        bolder: "#DFD8FD",
      },
      teal: {
        default: "#9DD9EE",
        bolder: "#C6EDFB",
      },
      magenta: {
        default: "#F797D2",
        bolder: "#FDD0EC",
      },
      gray: {
        default: "#9FADBC",
        bolder: "#C7D1DB",
      },
      lime: {
        default: "#B3DF72",
        bolder: "#D3F1A7",
      },
    },
    discovery: "#B8ACF6",
    brand: "#579DFF",
  },
  link: {
    default: "#579DFF",
    hovered: "#579DFF",
    pressed: "#85B8FF",
    visited: "#B8ACF6",
  },
  icon: {
    accent: {
      blue: "#388BFF",
      red: "#e2483d",
      orange: "#f38a3f",
      yellow: "#F5CD47",
      green: "#2ABB7F",
      purple: "#8F7EE7",
      teal: "#42B2D7",
      magenta: "#DA62AC",
      gray: "#738496",
      lime: "#82B536",
    },
    default: "#9FADBC",
    subtle: "#8C9BAB",
    inverse: "#161A1D",
    disabled: "#bfdbf847",
    brand: "#579DFF",
    selected: "#579DFF",
    danger: "#f15b50",
    success: "#2ABB7F",
    discovery: "#8F7EE7",
    information: "#388BFF",
    warning: {
      default: "#F5CD47",
      inverse: "#161A1D",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#a1bdd914",
    subtle: "#bcd6f00a",
  },
  opacity: {
    disabled: 0.2,
    loading: 0.4,
  },
  chart: {
    brand: {
      default: "#388BFF",
      hovered: "#579DFF",
    },
    neutral: {
      default: "#738496",
      hovered: "#8C9BAB",
    },
    success: {
      default: {
        "[default]": "#2ABB7F",
        hovered: "#4BCE97",
      },
      bold: {
        default: "#7EE2B8",
        hovered: "#BAF3DB",
      },
    },
    danger: {
      default: {
        "[default]": "#e2483d",
        hovered: "#f15b50",
      },
      bold: {
        default: "#fd9891",
        hovered: "#ffd5d2",
      },
    },
    warning: {
      default: {
        "[default]": "#CF9F02",
        hovered: "#E2B203",
      },
      bold: {
        default: "#F5CD47",
        hovered: "#F8E6A0",
      },
    },
    information: {
      default: {
        "[default]": "#1D7AFC",
        hovered: "#388BFF",
      },
      bold: {
        default: "#85B8FF",
        hovered: "#CCE0FF",
      },
    },
    discovery: {
      default: {
        "[default]": "#8270DB",
        hovered: "#8F7EE7",
      },
      bold: {
        default: "#B8ACF6",
        hovered: "#DFD8FD",
      },
    },
    categorical: {
      "1": {
        default: "#2898BD",
        hovered: "#42B2D7",
      },
      "2": {
        default: "#B8ACF6",
        hovered: "#DFD8FD",
      },
      "3": {
        default: "#e56910",
        hovered: "#f38a3f",
      },
      "4": {
        default: "#F797D2",
        hovered: "#FDD0EC",
      },
      "5": {
        default: "#CCE0FF",
        hovered: "#E9F2FF",
      },
      "6": {
        default: "#8270DB",
        hovered: "#8F7EE7",
      },
      "7": {
        default: "#FDD0EC",
        hovered: "#FFECF8",
      },
      "8": {
        default: "#fec195",
        hovered: "#fedec8",
      },
    },
    blue: {
      bold: {
        default: "#1D7AFC",
        hovered: "#388BFF",
      },
      bolder: {
        default: "#388BFF",
        hovered: "#579DFF",
      },
      boldest: {
        default: "#85B8FF",
        hovered: "#CCE0FF",
      },
    },
    red: {
      bold: {
        default: "#e2483d",
        hovered: "#f15b50",
      },
      bolder: {
        default: "#f15b50",
        hovered: "#f87168",
      },
      boldest: {
        default: "#fd9891",
        hovered: "#ffd5d2",
      },
    },
    orange: {
      bold: {
        default: "#f38a3f",
        hovered: "#fea362",
      },
      bolder: {
        default: "#fea362",
        hovered: "#fec195",
      },
      boldest: {
        default: "#fec195",
        hovered: "#fedec8",
      },
    },
    yellow: {
      bold: {
        default: "#CF9F02",
        hovered: "#E2B203",
      },
      bolder: {
        default: "#E2B203",
        hovered: "#F5CD47",
      },
      boldest: {
        default: "#F5CD47",
        hovered: "#F8E6A0",
      },
    },
    green: {
      bold: {
        default: "#2ABB7F",
        hovered: "#4BCE97",
      },
      bolder: {
        default: "#4BCE97",
        hovered: "#7EE2B8",
      },
      boldest: {
        default: "#7EE2B8",
        hovered: "#BAF3DB",
      },
    },
    teal: {
      bold: {
        default: "#42B2D7",
        hovered: "#6CC3E0",
      },
      bolder: {
        default: "#6CC3E0",
        hovered: "#9DD9EE",
      },
      boldest: {
        default: "#9DD9EE",
        hovered: "#C6EDFB",
      },
    },
    purple: {
      bold: {
        default: "#8270DB",
        hovered: "#8F7EE7",
      },
      bolder: {
        default: "#8F7EE7",
        hovered: "#9F8FEF",
      },
      boldest: {
        default: "#B8ACF6",
        hovered: "#DFD8FD",
      },
    },
    magenta: {
      bold: {
        default: "#CD519D",
        hovered: "#DA62AC",
      },
      bolder: {
        default: "#DA62AC",
        hovered: "#E774BB",
      },
      boldest: {
        default: "#F797D2",
        hovered: "#FDD0EC",
      },
    },
    gray: {
      bold: {
        default: "#596773",
        hovered: "#738496",
      },
      bolder: {
        default: "#738496",
        hovered: "#8C9BAB",
      },
      boldest: {
        default: "#8C9BAB",
        hovered: "#9FADBC",
      },
    },
    lime: {
      bold: {
        default: "#82B536",
        hovered: "#94C748",
      },
      bolder: {
        default: "#94C748",
        hovered: "#B3DF72",
      },
      boldest: {
        default: "#B3DF72",
        hovered: "#D3F1A7",
      },
    },
  },
};
