/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 27 Mar 2025 14:39:13 GMT
 */

export default {
  scale: {
    gray: {
      "100": "#EDEDED",
      "200": "#E1E1E1",
      "300": "#C4C4C4",
      "400": "#98999A",
      "500": "#797A7C",
      "600": "#565758",
      "700": "#494A4B",
      "800": "#2D2E2E",
      "900": "#191A1A",
      "000": "#F7F7F7",
    },
    neutral: {
      "100": "#F6F7FB",
      "200": "#ECEFF8",
      "300": "#DCDFEC",
      "400": "#C3C6D4",
      "500": "#878CA1",
      "600": "#73788C",
      "700": "#676879",
      "800": "#555A6D",
      "900": "#323338",
      "1000": "#262936",
      "1100": "#1F222D",
      "000": "#FFFFFF",
    },
    neutralAlpha: {
      "100": "#1f222d08",
      "200": "#1f222d0f",
      "300": "#1f222d24",
      "400": "#1f222d38",
      "500": "#1f222d70",
      "600": "#1f222d85",
      "700": "#1f222d9e",
      "800": "#1f222db8",
      "900": "#1f222dd9",
      "000": "#ffffff00",
    },
    blue: {
      "100": "#AED4FC",
      "200": "#67AEF9",
      "300": "#1E89FA",
      "400": "#0073EA",
      "500": "#1F76DB",
      "600": "#1F76C2",
      "700": "#0060B9",
      "800": "#003D75",
      "900": "#00284D",
      "000": "#CCE5FF",
    },
    teal: {
      "100": "#C1EBF1",
      "200": "#8BDAE4",
      "300": "#6ACFDC",
      "400": "#38BFD1",
      "500": "#2696A6",
      "600": "#1D717C",
      "700": "#175A63",
      "800": "#12484f",
      "900": "#0E393E",
      "000": "#D6F2F5",
    },
    purple: {
      "100": "#E7D5F6",
      "200": "#D0AEED",
      "300": "#B781E4",
      "400": "#A25DDC",
      "500": "#904ACF",
      "600": "#8050AB",
      "700": "#68418B",
      "800": "#4E3168",
      "900": "#342145",
      "000": "#F3EAFA",
    },
    green: {
      "100": "#BBDBC9",
      "200": "#B5CEC0",
      "300": "#89BDA0",
      "400": "#33995F",
      "500": "#00854D",
      "600": "#007A41",
      "700": "#007038",
      "800": "#006130",
      "900": "#004D26",
      "000": "#D6EBDF",
    },
    yellow: {
      "100": "#FFE580",
      "200": "#FBD850",
      "300": "#FFD329",
      "400": "#F5C300",
      "500": "#E5B700",
      "600": "#DBAF00",
      "700": "#C29E11",
      "800": "#755F0A",
      "900": "#463906",
      "000": "#FFEFB2",
    },
    red: {
      "100": "#F4C3CB",
      "200": "#ECB7BF",
      "300": "#E06C7D",
      "400": "#DD5569",
      "500": "#D83A52",
      "600": "#C53A4C",
      "700": "#B63546",
      "800": "#76232E",
      "900": "#4B161D",
      "000": "#FBE9EC",
    },
    orange: {
      "100": "#FCEBA1",
      "200": "#F2D973",
      "300": "#FFCB00",
      "400": "#F5C238",
      "500": "#EAAA15",
      "600": "#C58F12",
      "700": "#AA7909",
      "800": "#7C5803",
      "900": "#5A3F02",
      "000": "#FDF4CE",
    },
    magenta: {
      "100": "#FFC2E0",
      "200": "#FF8AC4",
      "300": "#FF5CAD",
      "400": "#FF3399",
      "500": "#FF158A",
      "600": "#E1197E",
      "700": "#C21E71",
      "800": "#610F39",
      "900": "#4B0C2C",
      "000": "#FFE5F2",
    },
    lime: {
      "100": "#E0F1BB",
      "200": "#CDE992",
      "300": "#B9E066",
      "400": "#A8D841",
      "500": "#9CD326",
      "600": "#89BA21",
      "700": "#7CA32B",
      "800": "#4D651B",
      "900": "#2E3D10",
      "000": "#ECF6D5",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#FFFFFF",
        hovered: "#ECEFF8",
        pressed: "#DCDFEC",
      },
      sunken: "#F6F7FB",
      raised: {
        default: "#FFFFFF",
        hovered: "#F6F7FB",
        pressed: "#ECEFF8",
      },
      overlay: {
        default: "#FFFFFF",
        hovered: "#ECEFF8",
        pressed: "#DCDFEC",
      },
    },
    shadow: {
      overlay: "0px 4px 8px 0px #00000033",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#1f222d0f",
        perimeter: "#1f222d24",
      },
      raised: "0px 1px 2px 0px #0000001a",
    },
  },
  border: {
    default: "#C3C6D4",
    bold: "#73788C",
    selected: "#0073EA",
    focused: "#1E89FA",
    subtle: "#ECEFF8",
    input: "#C3C6D4",
    inverse: "#FFFFFF",
    disabled: "#1f222d0f",
    brand: "#0073EA",
    danger: "#D83A52",
    warning: "#EAAA15",
    success: "#00854D",
    discovery: "#A25DDC",
    information: "#0073EA",
    accent: {
      blue: "#0073EA",
      red: "#D83A52",
      orange: "#EAAA15",
      yellow: "#F5C300",
      green: "#00854D",
      magenta: "#FF158A",
      purple: "#904ACF",
      teal: "#2696A6",
      gray: "#878CA1",
    },
    table: {
      default: "#1f222d24",
      container: "#D0D4E4",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#CCE5FF",
        subtler: "#AED4FC",
        subtle: "#1E89FA",
        bolder: "#1F76DB",
      },
      red: {
        subtlest: "#FBE9EC",
        subtler: "#F4C3CB",
        subtle: "#E06C7D",
        bolder: "#D83A52",
      },
      orange: {
        subtlest: "#FDF4CE",
        subtler: "#FCEBA1",
        subtle: "#F5C238",
        bolder: "#C58F12",
      },
      yellow: {
        subtlest: "#FFEFB2",
        subtler: "#FFE580",
        subtle: "#FFD329",
        bolder: "#DBAF00",
      },
      green: {
        subtlest: "#D6EBDF",
        subtler: "#BBDBC9",
        subtle: "#89BDA0",
        bolder: "#007A41",
      },
      purple: {
        subtlest: "#F3EAFA",
        subtler: "#E7D5F6",
        subtle: "#B781E4",
        bolder: "#8050AB",
      },
      teal: {
        subtlest: "#D6F2F5",
        subtler: "#C1EBF1",
        subtle: "#6ACFDC",
        bolder: "#1D717C",
      },
      magenta: {
        subtlest: "#FFE5F2",
        subtler: "#FFC2E0",
        subtle: "#FF5CAD",
        bolder: "#E1197E",
      },
      lime: {
        subtlest: "#ECF6D5",
        subtler: "#E0F1BB",
        subtle: "#A8D841",
        bolder: "#7CA32B",
      },
      gray: {
        subtlest: {
          default: "#ECEFF8",
          hovered: "#ECEFF8",
          pressed: "#C3C6D4",
        },
        subtler: {
          default: "#DCDFEC",
          hovered: "#C3C6D4",
          pressed: "#878CA1",
        },
        subtle: {
          default: "#878CA1",
          hovered: "#73788C",
          pressed: "#676879",
        },
        bolder: {
          default: "#73788C",
          hovered: "#676879",
          pressed: "#676879",
        },
      },
    },
    input: {
      default: "#FFFFFF",
      hovered: "#FFFFFF",
      pressed: "#FFFFFF",
    },
    inverse: {
      subtle: {
        default: "#1f222d24",
        hovered: "#1f222d38",
        pressed: "#1f222d70",
      },
    },
    neutral: {
      default: {
        "[default]": "#F6F7FB",
        hovered: "#DCDFEC",
        pressed: "#DCDFEC",
      },
      subtle: {
        default: "#ffffff00",
        hovered: "#DCDFEC",
        pressed: "#DCDFEC",
      },
      bold: {
        default: "#323338",
        hovered: "#323338",
        pressed: "#323338",
      },
    },
    brand: {
      subtlest: {
        default: "#CCE5FF",
        hovered: "#AED4FC",
        pressed: "#67AEF9",
      },
      bold: {
        default: "#0073EA",
        hovered: "#0060B9",
        pressed: "#0060B9",
      },
      boldest: {
        default: "#00284D",
        hovered: "#003D75",
        pressed: "#0060B9",
      },
    },
    selected: {
      default: {
        "[default]": "#CCE5FF",
        hovered: "#AED4FC",
        pressed: "#67AEF9",
      },
      bold: {
        default: "#1F76DB",
        hovered: "#0060B9",
        pressed: "#003D75",
      },
    },
    disabled: "#ECEFF8",
    information: {
      default: {
        "[default]": "#CCE5FF",
        hovered: "#AED4FC",
        pressed: "#67AEF9",
      },
      bold: {
        default: "#1F76DB",
        hovered: "#0060B9",
        pressed: "#003D75",
      },
    },
    danger: {
      default: {
        "[default]": "#F4C3CB",
        hovered: "#ECB7BF",
        pressed: "#E06C7D",
      },
      bold: {
        default: "#D83A52",
        hovered: "#C53A4C",
        pressed: "#76232E",
      },
    },
    success: {
      default: {
        "[default]": "#D6EBDF",
        hovered: "#BBDBC9",
        pressed: "#89BDA0",
      },
      bold: {
        default: "#00854D",
        hovered: "#007038",
        pressed: "#006130",
      },
    },
    discovery: {
      default: {
        "[default]": "#F3EAFA",
        hovered: "#E7D5F6",
        pressed: "#D0AEED",
      },
      bold: {
        default: "#8050AB",
        hovered: "#68418B",
        pressed: "#4E3168",
      },
    },
    warning: {
      default: {
        "[default]": "#FDF4CE",
        hovered: "#FCEBA1",
        pressed: "#F2D973",
      },
      bold: {
        default: "#FFCB00",
        hovered: "#EAAA15",
        pressed: "#C58F12",
      },
    },
  },
  blanket: {
    default: "#292f4cb3",
    selected: "#0060b914",
    danger: "#b635461a",
  },
  text: {
    default: "#323338",
    subtle: "#676879",
    subtlest: "#878CA1",
    disabled: "#1f222d85",
    selected: "#0073EA",
    inverse: "#FFFFFF",
    success: "#00854D",
    danger: "#D83A52",
    information: "#0060B9",
    warning: {
      default: "#7C5803",
      inverse: "#262936",
    },
    discovery: "#68418B",
    brand: "#0073EA",
    accent: {
      blue: {
        default: "#1F76C2",
        bolder: "#003D75",
      },
      red: {
        default: "#B63546",
        bolder: "#76232E",
      },
      orange: {
        default: "#7C5803",
        bolder: "#5A3F02",
      },
      yellow: {
        default: "#755F0A",
        bolder: "#463906",
      },
      green: {
        default: "#007A41",
        bolder: "#006130",
      },
      purple: {
        default: "#8050AB",
        bolder: "#4E3168",
      },
      teal: {
        default: "#1D717C",
        bolder: "#12484f",
      },
      magenta: {
        default: "#C21E71",
        bolder: "#610F39",
      },
      gray: {
        default: "#494A4B",
        bolder: "#191A1A",
      },
      lime: {
        default: "#4D651B",
        bolder: "#2E3D10",
      },
    },
  },
  link: {
    default: "#1F76C2",
    hovered: "#1F76C2",
    pressed: "#1F76C2",
    visited: "#1F76C2",
  },
  icon: {
    accent: {
      blue: "#0073EA",
      red: "#D83A52",
      orange: "#EAAA15",
      yellow: "#E5B700",
      green: "#00854D",
      purple: "#904ACF",
      teal: "#2696A6",
      magenta: "#FF158A",
      gray: "#73788C",
      lime: "#89BA21",
    },
    default: "#323338",
    subtle: "#676879",
    inverse: "#FFFFFF",
    disabled: "#C3C6D4",
    brand: "#0073EA",
    selected: "#0073EA",
    danger: "#D83A52",
    success: "#00854D",
    discovery: "#904ACF",
    information: "#1F76C2",
    warning: {
      default: "#C58F12",
      inverse: "#262936",
    },
  },
  interaction: {
    hovered: "#1f222d24",
    pressed: "#1f222d38",
  },
  skeleton: {
    default: "#1f222d0f",
    subtle: "#1f222d08",
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  chart: {
    brand: {
      default: "#0073EA",
      hovered: "#1F76DB",
    },
    neutral: {
      default: "#878CA1",
      hovered: "#73788C",
    },
    success: {
      default: {
        "[default]": "#00854D",
        hovered: "#007A41",
      },
      bold: {
        default: "#007038",
        hovered: "#006130",
      },
    },
    danger: {
      default: {
        "[default]": "#DD5569",
        hovered: "#D83A52",
      },
      bold: {
        default: "#B63546",
        hovered: "#76232E",
      },
    },
    warning: {
      default: {
        "[default]": "#EAAA15",
        hovered: "#C58F12",
      },
      bold: {
        default: "#AA7909",
        hovered: "#7C5803",
      },
    },
    information: {
      default: {
        "[default]": "#0073EA",
        hovered: "#1F76DB",
      },
      bold: {
        default: "#0060B9",
        hovered: "#003D75",
      },
    },
    discovery: {
      default: {
        "[default]": "#A25DDC",
        hovered: "#904ACF",
      },
      bold: {
        default: "#68418B",
        hovered: "#4E3168",
      },
    },
    categorical: {
      "1": {
        default: "#2696A6",
        hovered: "#1D717C",
      },
      "2": {
        default: "#68418B",
        hovered: "#4E3168",
      },
      "3": {
        default: "#EAAA15",
        hovered: "#C58F12",
      },
      "4": {
        default: "#C21E71",
        hovered: "#610F39",
      },
      "5": {
        default: "#003D75",
        hovered: "#00284D",
      },
      "6": {
        default: "#A25DDC",
        hovered: "#904ACF",
      },
      "7": {
        default: "#610F39",
        hovered: "#4B0C2C",
      },
      "8": {
        default: "#AA7909",
        hovered: "#7C5803",
      },
    },
    blue: {
      bold: {
        default: "#1E89FA",
        hovered: "#0073EA",
      },
      bolder: {
        default: "#1F76DB",
        hovered: "#1F76C2",
      },
      boldest: {
        default: "#0060B9",
        hovered: "#003D75",
      },
    },
    red: {
      bold: {
        default: "#DD5569",
        hovered: "#D83A52",
      },
      bolder: {
        default: "#C53A4C",
        hovered: "#B63546",
      },
      boldest: {
        default: "#76232E",
        hovered: "#4B161D",
      },
    },
    orange: {
      bold: {
        default: "#EAAA15",
        hovered: "#C58F12",
      },
      bolder: {
        default: "#C58F12",
        hovered: "#AA7909",
      },
      boldest: {
        default: "#AA7909",
        hovered: "#7C5803",
      },
    },
    yellow: {
      bold: {
        default: "#E5B700",
        hovered: "#DBAF00",
      },
      bolder: {
        default: "#DBAF00",
        hovered: "#C29E11",
      },
      boldest: {
        default: "#C29E11",
        hovered: "#755F0A",
      },
    },
    green: {
      bold: {
        default: "#00854D",
        hovered: "#007A41",
      },
      bolder: {
        default: "#007A41",
        hovered: "#007038",
      },
      boldest: {
        default: "#007038",
        hovered: "#006130",
      },
    },
    teal: {
      bold: {
        default: "#2696A6",
        hovered: "#1D717C",
      },
      bolder: {
        default: "#1D717C",
        hovered: "#175A63",
      },
      boldest: {
        default: "#175A63",
        hovered: "#12484f",
      },
    },
    purple: {
      bold: {
        default: "#A25DDC",
        hovered: "#904ACF",
      },
      bolder: {
        default: "#904ACF",
        hovered: "#8050AB",
      },
      boldest: {
        default: "#68418B",
        hovered: "#4E3168",
      },
    },
    magenta: {
      bold: {
        default: "#FF3399",
        hovered: "#FF158A",
      },
      bolder: {
        default: "#FF158A",
        hovered: "#E1197E",
      },
      boldest: {
        default: "#C21E71",
        hovered: "#610F39",
      },
    },
    gray: {
      bold: {
        default: "#878CA1",
        hovered: "#73788C",
      },
      bolder: {
        default: "#73788C",
        hovered: "#676879",
      },
      boldest: {
        default: "#555A6D",
        hovered: "#323338",
      },
    },
    lime: {
      bold: {
        default: "#9CD326",
        hovered: "#89BA21",
      },
      bolder: {
        default: "#89BA21",
        hovered: "#7CA32B",
      },
      boldest: {
        default: "#7CA32B",
        hovered: "#4D651B",
      },
    },
  },
};
